<template>
    <card-list>

        <subheader-list-item icon="fa-align-left" title="Customize"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Title" label-position="inside">
                        <template #message v-if="template">{{ template.title }}</template>
                        <b-input v-model="addon.override_title"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Single Cost" label-position="inside">
                        <template #message v-if="template">{{ template.single_cost | currency }}</template>
                        <b-input v-model="addon.override_price"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Double Cost" label-position="inside">
                        <template #message v-if="template">{{ template.double_cost | currency }}</template>
                        <b-input v-model="addon.override_price_double"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-credit-card-front" title="Payment Plan"/>

        <container-list-item padded>
            <div v-if="isnew">
                <div class="columns">
                    <div class="column">
                        <b-field>
                            <b-switch :true-value="1" :false-value="0" v-model="addon.plan_item">Adjust Registration Total and Create a Plan Item</b-switch>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <b-field label="Recalculation Strategy" label-position="inside">
                            <b-select expanded v-model="addon.strategy">
                                <option value="today">New Plan Item Due Today</option>
                                <option value="next">Adjust Next Installment Payment</option>
                                <option value="last">Adjust Last/Final Installment Payment</option>
                                <option value="spread">Spread Cost Over Remaining Installment Plan Items</option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
            </div>
            <div v-else>
                <i class="fas fa-exclamation-circle mr-2"></i>When editing or deleting an add-on, customizing the cost will not change the registration's cost or payment plan items.
                These will need to be adjusted manually if necessary.
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-tasks" title="Task"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select expanded v-model="addon.task_status">
                            <option value="planned">Planned</option>
                            <option value="booked">Booked</option>
                            <option value="confirmed">Confirmed</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payment" label-position="inside">
                        <b-select expanded v-model="addon.task_payment">
                            <option value="unpaid">Unpaid</option>
                            <option value="deposit">Deposit</option>
                            <option value="paid">Paid</option>
                            <option value="na">N/A</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Assignment" label-position="inside">
                        <b-select expanded v-model="addon.task_assigned_id">
                            <option :value="a.id" v-for="a in assignees">{{ a.first_name }} {{ a.last_name }}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-switch :native-value="1" :true-value="1" :false-value="0" v-model="addon.task_complete">Complete</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="addon.task_notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {mapGetters} from "vuex";

    export default {
        name: 'AddonForm',
        props: {
            addon: {
                type: Object,
                required: true
            },
            template: {
                type: Object,
                required: false
            },
            isnew: Boolean
        },
        components: {ContainerListItem, SubheaderListItem, CardList},
        computed: {
            ...mapGetters(['assignees'])
        }
    };

</script>

<style scoped>

</style>
